import React from "react";
import { FormattedMessage } from "react-intl";
import { isBrowser, logoutRedirect } from "@services/auth";

const LogoutCallbackPage = () => {
  console.log("Logging out");
  if (isBrowser) {
    logoutRedirect();
    window.location.replace(process.env.REACT_APP_URL ?? "/");
  }
  return <FormattedMessage id="LoggingOut" />;
};

export default LogoutCallbackPage;
